<template>
  <div>
    <ValidationObserver v-slot="{ invalid }">
      <div class="router-view-container">
        <div class="flex items-center space-x-4 mb-5">
          <vs-button @click="$router.go(-1)" border size="large">
            <svg-icon icon-class="arrow_left" className="primary"></svg-icon>
          </vs-button>

          <h1 class="page_title">{{ $route.meta.title }}</h1>
        </div>
        <div v-if="is_API_loaded" class="space-y-10">
          <section class="wrap">
            <div class="leftBlock">
              <h3 class="h3 sm:mt-4">Google Search Console</h3>
              <p>
                Google Search Console 是 Google
                開發的一個面向網站管理員的免費工具。網站管理員可以通過該工具了解自己網站的收錄情況並優化其網站的曝光率。
              </p>
            </div>
            <div class="rightBlock">
              <vs-alert color="success" :page.sync="page_google">
                <template #title>驗證網站擁有權</template>

                <template #page-1>
                  <div class="space-y-2">
                    <p>
                      1. 登入
                      <a href="https://search.google.com/search-console/about" target="_blank">Google Search Console</a>
                      後，新增資源(網址前置字元類型)並輸入您的網址 <span class="bg-white">{{ $store.state.getWebData.param.wlink }}</span
                      >，點選繼續。
                    </p>
                    <p>2. 驗證方式請選擇 「HTML 標記」。</p>
                  </div>
                </template>

                <template #page-2>
                  <div class="space-y-2">
                    <p>3. 您會取得一段程式碼，如下：</p>
                    <pre
                      class="bg-white w-fit ml-5"
                    >&lt;meta name="<span class="underline">xxxxx</span>" content="<span class="underline">xxxxxxxx</span>" /></pre>
                    <p>4. 將 name 和 content 中對應的<span class="underline">參數</span>貼入下方輸入框並儲存。</p>
                  </div>
                </template>

                <template #page-3>
                  <div class="space-y-2">
                    <p>5. 儲存後，回到 Google Search Console 頁面點選「驗證」。</p>
                    <p>6. 顯示驗證成功後即大功告成！</p>
                    <p class="text-gray-400">若需新增 Sitemap 網站地圖 可以繼續前往下一頁。</p>
                  </div>
                </template>
                <template #page-4>
                  <div class="space-y-2">
                    <p class="font-bold">- 如何新增 Sitemap?</p>
                    <p>1.在 「輸入 Sitemap 網址」 輸入框中，輸入 <span class="bg-white">sitemap.xml</span>。</p>
                    <p>2.點選 「提交」 按鈕。</p>
                    <p>3.狀態顯示成功後即大功告成！</p>
                  </div>
                </template>
              </vs-alert>

              <div>
                <h4 class="h4 mb-1">name</h4>
                <ValidationProvider tag="div" class="relative" name="name" vid="google_name" rules="" v-slot="{ errors }">
                  <vs-input v-model="data.gseo_name"></vs-input>
                  <errorDiv>{{ errors[0] }}</errorDiv>
                </ValidationProvider>
              </div>
              <div>
                <h4 class="h4 mb-1">content</h4>
                <ValidationProvider tag="div" class="relative" name="content" vid="google_content" rules="" v-slot="{ errors }">
                  <vs-input v-model="data.gseo_cont"></vs-input>
                  <errorDiv>{{ errors[0] }}</errorDiv>
                </ValidationProvider>
              </div>
              <p class="text-right">* 為了保持驗證狀態，即使驗證成功後，也請勿移除和更改。</p>
            </div>
          </section>

          <hr />

          <section class="wrap">
            <div class="leftBlock">
              <h3 class="h3 sm:mt-4">Bing Webmaster Tools</h3>
              <p>
                Bing Webmaster Tools 是 Microsoft
                開發的一個面向網站管理員的免費工具。網站管理員可以通過該工具了解自己網站的收錄情況並優化其網站的曝光率。
              </p>
            </div>
            <div class="rightBlock">
              <vs-alert color="success" :page.sync="page_bing">
                <template #title>驗證網站擁有權</template>

                <template #page-1>
                  <div class="space-y-2">
                    <p>
                      1. 登入
                      <a href="https://www.bing.com/webmasters/about" target="_blank">Bing Webmaster Tools</a>
                      後，輸入您的網址 <span class="bg-white">{{ $store.state.getWebData.param.wlink }}</span
                      >，點選新增。
                    </p>
                    <p>2. 驗證方式請選擇 「HTML 中繼標籤」。</p>
                  </div>
                </template>

                <template #page-2>
                  <div class="space-y-2">
                    <p>3. 您會取得一段程式碼，如下：</p>
                    <pre
                      class="bg-white w-fit ml-5"
                    >&lt;meta name="<span class="underline">xxxxx</span>" content="<span class="underline">xxxxxxxx</span>" /></pre>
                    <p>4. 將 name 和 content 中對應的<span class="underline">參數</span>貼入下方輸入框並儲存。</p>
                  </div>
                </template>

                <template #page-3>
                  <div class="space-y-2">
                    <p>5. 儲存後，回到 Bing Webmaster Tools 頁面點選「驗證」。</p>
                    <p>6. 顯示驗證成功後即大功告成！</p>
                    <p class="text-gray-400">若需新增 Sitemap 網站地圖 可以繼續前往下一頁。</p>
                  </div>
                </template>
                <template #page-4>
                  <div class="space-y-2">
                    <p class="font-bold">- 如何新增 網站地圖?</p>
                    <p>
                      1. 點選 「提交網站地圖」，輸入
                      <span class="bg-white">{{ $store.state.getWebData.param.wlink }}/sitemap.xml</span> 並提交。
                    </p>
                    <p>2. 狀態顯示成功後即大功告成！</p>
                  </div>
                </template>
              </vs-alert>

              <div>
                <h4 class="h4 mb-1">name</h4>
                <ValidationProvider tag="div" class="relative" name="name" vid="bing_name" rules="" v-slot="{ errors }">
                  <vs-input v-model="data.bseo_name"></vs-input>
                  <errorDiv>{{ errors[0] }}</errorDiv>
                </ValidationProvider>
              </div>
              <div>
                <h4 class="h4 mb-1">content</h4>
                <ValidationProvider tag="div" class="relative" name="content" vid="bing_content" rules="" v-slot="{ errors }">
                  <vs-input v-model="data.bseo_cont"></vs-input>
                  <errorDiv>{{ errors[0] }}</errorDiv>
                </ValidationProvider>
              </div>
              <p class="text-right">* 為了保持驗證狀態，即使驗證成功後，也請勿移除和更改。</p>
            </div>
          </section>
          <hr />
          <div class="flex items-center justify-end">
            <!-- <router-link :to="{ name: 'setting' }">
              <vs-button border size="large">取消 </vs-button>
            </router-link> -->
            <vs-button :disabled="invalid" size="large" ref="saveBtn" @click="saveData(invalid)">儲存</vs-button>
          </div>
        </div>

        <!-- 骨架屏 -->
        <div class="space-y-4" v-else>
          <section v-for="(item, index) in 2" :key="index" class="wrap">
            <div class="leftBlock">
              <skeleton class="mt-4" width="100px" />
              <skeleton width="200px" height="10px" />
              <skeleton width="200px" height="10px" />
              <skeleton width="200px" height="10px" />
            </div>
            <div class="rightBlock">
              <div class="flex items-center justify-between">
                <skeleton width="100px" />
                <skeleton width="100px" />
              </div>
              <div class="flex items-start flex-col space-y-2">
                <skeleton v-for="(item, index) in 3" :key="index" width="200px" height="10px" />
              </div>
            </div>
          </section>
        </div>
      </div>
    </ValidationObserver>
  </div>
</template>

<script>
export default {
  name: 'sitemap',
  data() {
    return {
      is_API_loaded: false,
      data: {},
      page_google: 1,
      page_bing: 1,
    }
  },
  created() {
    this.getData()
  },
  methods: {
    // 4.4.8.1 SEO HTML 標記資料-讀取
    getData() {
      this.$axios({
        url: 'front/set/app/getSEOData.php',
        headers: {
          stoken: this.$store.state.login.userInfo.stoken,
        },
        kcval: true,
        data: {
          lang: 0,
        },
      }).then((res) => {
        if (res.data.Success) {
          this.data = res.data.Data
          this.is_API_loaded = true
        } else {
          this.$myVS.openNoti('發生錯誤，請稍候再試', res.data.Message, 'danger', 10000)
        }
      })
    },
    // 4.4.8.2 SEO HTML 標記資料-修改
    saveData(invalid) {
      if (invalid) return

      const loading = this.$vs.loading({
        target: this.$refs.saveBtn,
        background: 'primary',
        scale: '0.6',
        opacity: 1,
        color: '#fff',
      })

      this.$axios({
        url: 'front/set/app/uSEOData.php',
        headers: {
          stoken: this.$store.state.login.userInfo.stoken,
        },
        kcval: true,
        data: {
          lang: 0,
          gseo_name: this.data.gseo_name,
          gseo_cont: this.data.gseo_cont,
          bseo_name: this.data.bseo_name,
          bseo_cont: this.data.bseo_cont,
        },
      }).then((res) => {
        loading.close()
        if (res.data.Success) {
          this.getData()
          this.$myVS.openNoti(res.data.Message, '', undefined)
        } else {
          this.$myVS.openNoti('發生錯誤，請稍候再試', res.data.Message, 'danger', 10000)
        }
      })
    },
  },
}
</script>
<style lang="scss" scoped>
.wrap {
  @apply flex flex-col sm:flex-row sm:space-x-10 space-y-4 sm:space-y-0;
}

.leftBlock {
  @apply w-full sm:w-1/3 space-y-4 flex-shrink-0;
}

.rightBlock {
  @apply flex-grow h-fit bg-white p-4 rounded-xl space-y-4;
}
</style>
